import sendRequest from "@/libs/sendRequest";

export  const categories = {
    namespaced: true,
    state:{
        category: [],
        categories: [],
        total_categories: null
    },
    getters: {
        getCategory: (state) => (state.category),
        getCategories: (state) => (state.categories),
        getTotalCategories: (state) => (state.total_categories)
    },
    mutations: {
        setCategory: (state, payload) => state.category = payload,
        setCategories: (state, payload) => state.categories = payload,
        setTotalCategories: (state, payload) => state.total_categories = payload
    },
    actions: {
        list: async({commit, rootGetters}, page) =>{

            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest(`administrator/categories/list?pagina=${page}`, 'GET', null, null, null, custom_headers)

            if(response.success && response.data) {
                commit('setCategories', response.data.cliente)
            }

            else if(!response.success && response.errors) {
                for(let error in response.errors) {
                    console.log(response.errors[error])
                }
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        },
        view: async({commit, rootGetters}, query) =>{
    
            const custom_headers = rootGetters.getHeaders;
    
            const response = await sendRequest('administrator/categories/view', 'GET', null, null, query, custom_headers)
    
            if(response.success && response.data) {
                commit('setCategory', response.data.category)
            }
        },
        add: async({commit, dispatch, rootGetters}, body) =>{
    
            const custom_headers = rootGetters.getHeaders;

    
            const response = await sendRequest('administrator/categories/save', 'POST', null, body, null, custom_headers)
    
            if(response.success) {
                dispatch('showAlert', 'Categoría añadida con éxito.', { root: true})
            }

            else if(!response.success) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'}, 
                        {root: true})
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido con la conexión con la API')
            }

        },
        update: async({commit, dispatch, rootGetters}, body) =>{
            const custom_headers = rootGetters.getHeaders
            
    
            const response = await sendRequest('administrator/categories/update', 'PUT', null, body, null, custom_headers)
            
            if(response.success) {
                dispatch('showAlert', 'Categoría con éxito.', { root: true})
            }

            else if(!response.success) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'}, 
                        {root: true})
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido con la conexión con la API')
            }
        },
        inactive: async({commit, dispatch, rootGetters}, query) =>{
    
            const custom_headers = rootGetters.getHeaders;
    
            const response = await sendRequest('administrator/categories/active', 'GET', null, null, query, custom_headers)
    
            if(response.success) {
                dispatch('showAlert', 'Categoria eliminada con éxito.', { root: true})
            }

            else if(!response.success) {
                commit('setAlert', 
                    {   open: true, 
                        variant: 'danger', 
                        message: 'Un error ha ocurrido intente más tarde.'}, 
                        {root: true})
            }

            else if(response.success && !response.data) {
                console.log('Se ha perdido con la conexión con la API')
            }
        },
        family: async({ commit, rootGetters}, query) => {
            const custom_headers = rootGetters.custom_headers;

            const response = await sendRequest('administrator/categories/family', 'GET', null, null, query, custom_headers);

            if(response.success && response.data) {
                
            }
        },
        total: async({commit, rootGetters}) => {
            const custom_headers = rootGetters.getHeaders;

            const response = await sendRequest('administrator/categories/total', 'GET', null, null, null, custom_headers);

            if(response.success && response.data) {
                commit('setTotalCategories', response.data.total.Total)
            }
        },
        categoriesById: async({ commit, rootGetters}, query) => {
            const custom_headers = rootGetters.getHeaders;
            
            const response = await sendRequest('administrator/family/categories', 'GET', null, null, query, custom_headers);
            if(response.success && response.data) {
                commit('setCategories', response.data.family ?? [])
            }

            else if(!response.success && response.errors) {
                console.log((await response).errors)
            }

            else if(response.success && !response.data) {
                console.log('Se perdio la conexión con la API')
            }
        }
    }

}